<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <!-- <vuexy-logo /> -->
      <custom-logo />

      <h2 class="brand-text text-primary ml-1 mr-2">
        {{ navbarTitle }}
      </h2>
      <!-- <b-button
        size="sm"
        variant="primary"
        @click="logout"
      >
        <span class="mr-25 align-middle"> {{ $t("Logout") }}</span>
      </b-button> -->
      <b-dropdown
        :text="getUserData != null ? `${getUserData.firstName} ${getUserData.lastName}` : ''"
        variant="gradient-primary"
        class="mr-2"
      >
        <!-- <b-dropdown-item>
          {{ $t("Company") }}
        </b-dropdown-item>
        <b-dropdown-item>
          {{ $t("Application") }}
        </b-dropdown-item>
        <b-dropdown-item>
          {{ $t("Staff") }}
        </b-dropdown-item> -->
        <!-- <b-dropdown-divider /> -->
        <b-dropdown-item @click="logout">
          {{ $t("Logout") }}
        </b-dropdown-item>
      </b-dropdown>
      <b-button
        type="submit"
        variant="danger"
        block
        @click="cancel"
      >
        {{ $t("Back") }}
      </b-button>
    </b-link>
    <div class="p-sm-4">
      <div class="w-full">
        <b-overlay
          :show="show"
          rounded="sm"
          class="mt-5"
        >
          <b-card
            no-body
            class="mb-2"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="BookIcon"
                  size="18"
                />
                <!-- <span class="align-middle ml-50">{{ $route.params.serviceType.toUpperCase() }}</span> -->
              </b-card-title>
            </b-card-header>
            <validation-observer ref="formData">
              <b-form class="p-2">
                <b-row>
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('Image')"
                      label-for="register-image"
                    >
                      <!-- media -->
                      <b-media no-body>
                        <b-media-aside>
                          <b-link>
                            <b-img
                              v-if="image"
                              ref="previewEl"
                              rounded
                              :src="image"
                              height="80"
                            />
                            <b-img
                              v-else
                              ref="previewEl"
                              :src="require('@/assets/images/blank/no_image.png')"
                              rounded
                              height="80"
                            />
                          </b-link>
                          <!--/ avatar -->
                        </b-media-aside>

                        <b-media-body class="mt-75 ml-75">
                          <!-- upload button -->
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            size="sm"
                            class="mb-75 mr-75"
                            @click="$refs.refInputImage.$el.click()"
                          >
                            {{ $t("Upload") }}
                          </b-button>
                          <b-form-file
                            ref="refInputImage"
                            accept=".webp, .jpeg, .jpg, .png, .gif"
                            :hidden="true"
                            plain
                            @change="inputImageRenderer"
                          />
                          <!--/ upload button -->

                          <!-- reset -->
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            size="sm"
                            class="mb-75 mr-75"
                            @click="resetImage"
                          >
                            {{ $t("Reset") }}
                          </b-button>
                          <!--/ reset -->
                          <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB</b-card-text>
                        </b-media-body>
                      </b-media>
                      <!--/ media -->
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <b-card-text>{{ $t("Cover") }} {{ $t("All") }}
                        {{ cover.length }} {{ $t("Cover") }}</b-card-text>
                      <!-- media -->
                      <b-media no-body>
                        <b-media-aside>
                          <b-row>
                            <b-col
                              v-for="(item, index) in cover"
                              :key="index"
                              :cols="cover.length < 4?'12':'3'"
                            >
                              <b-img
                                fluid-grow
                                :src="item"
                                rounded
                                class="mr-1 mb-1 imgs-show"
                                height="120"
                              />
                              <b-button
                                variant="danger"
                                class="btn-remove"
                                @click="removeCover(index)"
                              >
                                {{ $t("Delete") }} ({{ index+1 }})
                              </b-button>
                            </b-col>
                          </b-row>

                          <!--/ avatar -->
                        </b-media-aside>
                        <b-media-body class="mt-75 ml-75">
                          <!-- upload button -->
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            size="sm"
                            class="mb-75 mr-75"
                            @click="$refs.refInputCover.$el.click()"
                          >
                            {{ $t("Upload") }}
                          </b-button>
                          <b-form-file
                            ref="refInputCover"
                            accept=".webp, .jpeg, .jpg, .png, .gif"
                            :hidden="true"
                            plain
                            @change="inputCoverRenderer"
                          />
                          <!--/ upload button -->

                          <!-- reset -->
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            size="sm"
                            class="mb-75 mr-75"
                            @click="resetCover"
                          >
                            {{ $t("Reset") }}
                          </b-button>
                          <!--/ reset -->
                          <b-card-text>{{ $t("Allowed") }} JPG, GIF {{ $t("or") }} PNG.
                            {{ $t("Max size of") }} 300KB</b-card-text>
                        </b-media-body>
                      </b-media>
                      <!--/ media -->
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <!-- refId -->
                    <b-form-group
                      :label="$t('Establishment name')"
                      label-for="register-firstName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="firstName"
                        vid="firstName"
                        rules="required"
                      >
                        <b-form-input
                          id="register-firstName"
                          v-model="firstName"
                          name="register-firstName"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('Establishment name')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- <b-col cols="6">
                    <b-form-group
                      :label="$t('Last Name')"
                      label-for="register-lastName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="lastName"
                        vid="lastName"
                        rules=""
                      >
                        <b-form-input
                          id="register-lastName"
                          v-model="lastName"
                          name="register-lastName"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('Last Name')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <b-col
                    v-if="$route.params.id === 'add'"
                    cols="6"
                  >
                    <!-- refId -->
                    <b-form-group
                      :label="$t('Phone Number')"
                      label-for="register-phoneNumber"
                    >
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          TH (+66)
                        </b-input-group-prepend>
                        <cleave
                          id="register-phoneNumber"
                          v-model="tel"
                          class="form-control"
                          :raw="false"
                          :options="{
                            phone: true,
                            phoneRegionCode: 'TH',
                          }"
                          placeholder="123456789"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('Gender')"
                      label-for="register-gender"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="gender"
                        vid="gender"
                        rules="required"
                      >
                        <v-select
                          id="register-gender"
                          v-model="gender"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="OptionGender"
                          :reduce="(OptionGender) => OptionGender.value"
                          class="select-size-lg"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group
                      :label="$t('Service Area')"
                      label-for="register-serviceAreaId"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="serviceAreaId"
                        vid="serviceAreaId"
                        rules=""
                      >
                        <v-select
                          id="register-serviceAreaId"
                          v-model="serviceAreaId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="serviceAreaData"
                          :reduce="(serviceAreaData) => serviceAreaData._id"
                          class="select-size-lg"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('Service Type') +' '+$t('level')+ ' '+1"
                      label-for="register-serviceTypeId"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="serviceTypeId"
                        vid="serviceTypeId"
                        rules=""
                      >
                        <v-select
                          id="register-serviceTypeId"
                          v-model="selected1"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="serviceType1"
                          :reduce="(serviceType1) => serviceType1._id"
                          class="select-size-lg"
                          @input="selectServiceType1Id($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <b-col
                    v-if="serviceType2.length>0"
                    cols="6"
                  >
                    <b-form-group
                      :label="$t('Service Type') +' '+$t('level')+ ' '+2"
                      label-for="register-serviceTypeId"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="serviceTypeId"
                        vid="serviceTypeId"
                        rules=""
                      >
                        <v-select
                          id="register-serviceTypeId"
                          v-model="selected2"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="serviceType2"
                          :reduce="(serviceType2) => serviceType2._id"
                          class="select-size-lg"
                          @input="selectServiceType2Id($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <b-col
                    v-if="serviceType3.length>0"
                    cols="6"
                  >
                    <b-form-group
                      :label="$t('Service Type') +' '+$t('level')+ ' '+3"
                      label-for="register-serviceTypeId"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="serviceTypeId"
                        vid="serviceTypeId"
                        rules=""
                      >
                        <v-select
                          id="register-serviceTypeId"
                          v-model="selected3"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="serviceType3"
                          :reduce="(serviceType3) => serviceType3._id"
                          class="select-size-lg"
                          @input="selectServiceType3Id($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <b-col
                    v-if="partnerTemplateTimeData.length>0"
                    cols="6"
                  >
                    <b-form-group
                      :label="$t('Template Time')"
                      label-for="register-partnerTemplateTime"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="partnerTemplateTime"
                        vid="partnerTemplateTime"
                        rules=""
                      >
                        <v-select
                          id="register-partnerTemplateTime"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="partnerTemplateTimeData"
                          :reduce="(partnerTemplateTimeData) => partnerTemplateTimeData.workingPeriod"
                          class="select-size-lg"
                          @input="templateTime($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <b-col
                    v-if="servicePackageData.length>0"
                    cols="6"
                  >
                    <b-form-group
                      :label="$t('Package')"
                      label-for="register-package"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="package"
                        vid="package"
                        rules=""
                      >
                        <v-select
                          id="register-package"
                          v-model="servicePackageId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="servicePackageData"
                          :reduce="(servicePackageData) => servicePackageData._id"
                          class="select-size-lg"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <!-- refId -->
                    <b-form-group
                      :label="$t('Working Period')"
                      label-for="register-workingPeriod"
                    >
                      <b-table
                        striped
                        responsive
                        class="mb-0"
                        :items="workingPeriod"
                        :fields="tableWorkingPeriodColumns"
                      >
                        <template #cell(day)="data">
                          <p class="align-middle">
                            {{ $t(workingPeriod[data.index].day) }}
                          </p>
                          <p
                            v-if="workingPeriod[data.index].openTime.hour !==null && workingPeriod[data.index].openTime.minute !==null
                              && workingPeriod[data.index].closeTime.hour !==null && workingPeriod[data.index].closeTime.minute !== null
                            "
                            class="align-middle"
                          >
                            {{ $t('Moment 1') }} : ({{ timeFomat(workingPeriod[data.index].openTime.hour) }}:{{ timeFomat(workingPeriod[data.index].openTime.minute) }}-{{ timeFomat(workingPeriod[data.index].closeTime.hour) }}:{{ timeFomat(workingPeriod[data.index].closeTime.minute) }})
                          </p>
                          <p
                            v-if="workingPeriod[data.index].openTime2.hour !==null && workingPeriod[data.index].openTime2.minute !==null
                              && workingPeriod[data.index].closeTime2.hour !==null && workingPeriod[data.index].closeTime2.minute !== null
                            "
                            class="align-middle"
                          >
                            {{ $t('Moment 2') }} : ({{ timeFomat(workingPeriod[data.index].openTime2.hour) }}:{{ timeFomat(workingPeriod[data.index].openTime2.minute) }}-{{ timeFomat(workingPeriod[data.index].closeTime2.hour) }}:{{ timeFomat(workingPeriod[data.index].closeTime2.minute) }})
                          </p>
                        </template>
                        <template #cell(openTime)="data">
                          <b-row>
                            <b-col cols="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Hour"
                                vid="hour"
                                rules="min:1|max:2|between:0,23"
                              >
                                <b-form-input
                                  :id="'openHour'+data.index"
                                  v-model="workingPeriod[data.index].openTime.hour"
                                  name="hour"
                                  :placeholder="$t('Hour')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col cols="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Minute"
                                vid="minute"
                                rules="min:1|max:2|between:0,59"
                              >
                                <b-form-input
                                  :id="'openMinute'+data.index"
                                  v-model="workingPeriod[data.index].openTime.minute"
                                  name="minute"
                                  :placeholder="$t('Minute')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                          </b-row>

                        </template>
                        <template #cell(closeTime)="data">
                          <b-row>
                            <b-col cols="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Hour"
                                vid="hour"
                                rules="min:1|max:2|between:0,23"
                              >
                                <b-form-input
                                  :id="'closeHour'+data.index"
                                  v-model="workingPeriod[data.index].closeTime.hour"
                                  name="hour"
                                  :placeholder="$t('Hour')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col cols="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Minute"
                                vid="minute"
                                rules="min:1|max:2|between:0,59"
                              >
                                <b-form-input
                                  :id="'closeMinute'+data.index"
                                  v-model="workingPeriod[data.index].closeTime.minute"
                                  name="minute"
                                  :placeholder="$t('Minute')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                          </b-row>

                        </template>
                        <template #cell(openTime2)="data">
                          <b-row>
                            <b-col cols="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Hour"
                                vid="hour"
                                rules="min:1|max:2|between:0,23"
                              >
                                <b-form-input
                                  :id="'open2Hour'+data.index"
                                  v-model="workingPeriod[data.index].openTime2.hour"
                                  name="hour"
                                  :placeholder="$t('Hour')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col cols="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Minute"
                                vid="minute"
                                rules="min:1|max:2|between:0,59"
                              >
                                <b-form-input
                                  :id="'open2Minute'+data.index"
                                  v-model="workingPeriod[data.index].openTime2.minute"
                                  name="minute"
                                  :placeholder="$t('Minute')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                          </b-row>

                        </template>
                        <template #cell(closeTime2)="data">
                          <b-row>
                            <b-col cols="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Hour"
                                vid="hour"
                                rules="min:1|max:2|between:0,23"
                              >
                                <b-form-input
                                  :id="'close2Hour'+data.index"
                                  v-model="workingPeriod[data.index].closeTime2.hour"
                                  name="hour"
                                  :placeholder="$t('Hour')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col cols="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Minute"
                                vid="minute"
                                rules="min:1|max:2|between:0,59"
                              >
                                <b-form-input
                                  :id="'close2Minute'+data.index"
                                  v-model="workingPeriod[data.index].closeTime2.minute"
                                  name="minute"
                                  :placeholder="$t('Minute')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                          </b-row>

                        </template>
                        <template #cell(action)="data">
                          <b-form-checkbox
                            v-model="workingPeriod[data.index].status"
                            class="custom-control-primary"
                            name="check-button"
                            switch
                          />
                        </template>
                      </b-table>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <!-- refId -->
                    <b-form-group
                      :label="$t('Call Table')"
                      label-for="register-callTable"
                    >
                      <b-form-checkbox
                        v-model="callTableStatus"
                        switch
                        inline
                      />
                      <b-table
                        v-if="callTableStatus"
                        striped
                        responsive
                        class="mb-0"
                        :items="callTable"
                        :fields="tableCallColumns"
                      >
                        <template #cell(start)="data">
                          <b-form-input
                            :id="'start'+data.index"
                            v-model="callTable[data.index].start"
                            name="start"
                            :placeholder="$t('Start')"
                          />
                        </template>
                        <template #cell(end)="data">
                          <b-form-input
                            :id="'end'+data.index"
                            v-model="callTable[data.index].end"
                            name="end"
                            :placeholder="$t('End')"
                          />
                        </template>
                        <template #cell(price)="data">
                          <b-form-input
                            :id="'price'+data.index"
                            v-model="callTable[data.index].price"
                            name="price"
                            :placeholder="$t('Price')"
                          />
                        </template>
                        <template #cell(action)="data">
                          <b-button
                            v-if="data.index === callTable.length-1"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="d-flex"
                            @click="addCallTable()"
                          >
                            <feather-icon
                              icon="PlusIcon"
                            />
                          </b-button>
                          <b-button
                            v-if="data.index !== callTable.length-1"

                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="d-flex"
                            @click="deleteCallTable(data.index)"
                          >
                            <feather-icon
                              icon="TrashIcon"
                            />
                          </b-button>
                        </template>
                      </b-table>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col
                    cols="12"
                  >
                    <b-form-group
                      :label="$t('Template Detail')"
                      label-for="register-partnerTemplateDetail"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="partnerTemplateDetail"
                        vid="partnerTemplateDetail"
                        rules=""
                      >
                        <v-select
                          id="register-partnerTemplateDetail"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="partnerTemplateDetailData"
                          :reduce="(partnerTemplateDetailData) => partnerTemplateDetailData"
                          class="select-size-lg"
                          @input="templateDetail($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <b-col
                    v-for="(item , index) in questions"
                    :key="index"
                    cols="12"
                  >
                    <b-row class="ml-1">
                      <p><b>{{ item.question }}</b></p>
                    </b-row>
                    <validation-provider
                      v-if="item.type == 'short_message'"
                      #default="{ errors }"
                      :name="'question' + index"
                      :vid="'question' + index"
                      :rules="item.required ? 'required' : ''"
                    >
                      <b-form-input
                        :id="'register-' + 'question' + index"
                        v-model="item.answer"
                        class="mb-1"
                        :name="'register-' + 'question' + index"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Answer')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <div v-if="item.type == 'many_options'">
                      <b-col
                        v-for="(itemlist , i) in item.listOption"
                        :key="i"
                        cols="3"
                      >
                        <b-row>
                          <b-card-text class="ml-2 mr-2">
                            {{ itemlist.option }}
                          </b-card-text>
                          <b-form-checkbox
                            :checked="itemlist.select"
                            class="custom-control-primary"
                            name="check-button"
                            switch
                          />
                        </b-row>
                      </b-col>
                    </div>
                  </b-col> -->
                  <b-col
                    cols="12"
                  >
                    <GmapMap
                      ref="mapRef"
                      :style="'height:500px'"
                      :center="dataCenter"
                      :zoom="zoom"
                      @click="drawInMap"
                    >
                      <GmapMarker
                        :draggable="true"
                        :position="dataCenter"
                        :clickable="true"
                        @dragend="setLocal"
                      />
                    </GmapMap>
                  </b-col>
                  <b-row class="mt-2 align-items-center">
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Lat')"
                        label-for="register-lat"
                      >
                        <b-form-input
                          v-model="lat"
                          name="register-lat"
                          :placeholder="$t('Lat')"
                          @input="setCenter($event, 'lat')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Lng')"
                        label-for="register-lng"
                      >
                        <b-form-input
                          v-model="lng"
                          name="register-lng"
                          :placeholder="$t('Lng')"
                          @input="setCenter($event, 'lng')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-row>
                <!-- Action button -->
                <div class="d-flex mt-2">
                  <b-button
                    variant="primary"
                    class="mr-2"
                    @click="validationForm()"
                  >
                    {{ $t("Submit") }}
                  </b-button>
                  <b-button
                    type="button"
                    variant="outline-secondary"
                    @click="cancel"
                  >
                    {{ $t("Cancel") }}
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
  BButton,
  BOverlay,
  BTable,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import CustomLogo from '@/Logo.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, storeGroceryAbility, storeFoodAbility, storeQueueAbility, driverDeliveryAbility, driverCallAbility, servicesAbility,
} from '@/libs/acl/config'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import storeModule from '@/store/ProfilePartner/index'

const STORE_MODULE_NAME = 'profilePartner'

export default {
  components: {
    Cleave,
    CustomLogo,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BButton,
    BOverlay,
    BTable,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    vSelect,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      show: false,
      dataId: null,
      servicePackageId: '',
      serviceAreaId: '',
      serviceTypeId: '',
      image: '',
      cover: [],
      firstName: '',
      lastName: '',
      tel: '',
      workingPeriod: [],
      callTableStatus: false,
      callTable: [
        {
          start: '', // ระยะทางเริ่มต้น
          end: '', // ระยะสิ้นสุด
          price: '', // ราคา
        },
      ],
      partnerLocation: {
        type: 'Point',
        coordinates: [103.652653, 16.056734],
      },
      dataCenter: { lat: 16.056734, lng: 103.652653 },
      lat: 16.056734,
      lng: 103.652653,
      zoom: 12,
      gender: 'none',
      licensePlate: '',
      typeProfile: '',
      questions: [],
      searchQuery1: '',
      searchQuery2: '',
      searchQuery3: '',
      selected1: null,
      selected2: null,
      selected3: null,
      required,
    }
  },
  computed: {
    serviceType1() {
      const regex = new RegExp(this.searchQuery1, 'i')
      const result = this.respDataServiceType.filter(p => p.position === 0 && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))))
      return this.formatData(result) || []
    },
    serviceType2() {
      let result = []
      const regex = new RegExp(this.searchQuery2, 'i')

      if (this.selected1) {
        result = this.respDataServiceType.filter(p => p.previousServiceTypeId === this.selected1 && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))))
      }
      return this.formatData(result) || []
    },
    serviceType3() {
      let result = []
      const regex = new RegExp(this.searchQuery3, 'i')

      if (this.selected2) {
        result = this.respDataServiceType.filter(p => p.previousServiceTypeId === this.selected2 && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))))
      }
      return this.formatData(result) || []
    },
    respDataServiceType() {
      return store.state[STORE_MODULE_NAME].respServiceTypeData != null ? store.state[STORE_MODULE_NAME].respServiceTypeData.data : []
    },
    serviceTypeData() {
    //   return store.state[STORE_MODULE_NAME].respServiceTypeData != null ? store.state[STORE_MODULE_NAME].respServiceTypeData.data : []
      if (store.state[STORE_MODULE_NAME].respServiceTypeData !== null) {
        return this.formatData(store.state[STORE_MODULE_NAME].respServiceTypeData.data)
      }
      return []
    },
    serviceAreaData() {
      return []
    },
    partnerTemplateTimeData() {
      return store.state[STORE_MODULE_NAME].respTemplateTimeData != null ? store.state[STORE_MODULE_NAME].respTemplateTimeData.data : []
    },
    servicePackageData() {
      return []
    },
    OptionGender() {
      return [
        { name: this.$t('None'.toUpperCase()), value: 'none' },
        { name: this.$t('Male'.toUpperCase()), value: 'male' },
        { name: this.$t('Female'.toUpperCase()), value: 'female' },
      ]
    },
    tableCallColumns() {
      return [
        { key: 'start', label: this.$t('Start'), sortable: false },
        { key: 'end', label: this.$t('End'), sortable: false },
        { key: 'price', label: this.$t('Price'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    tableWorkingPeriodColumns() {
      return [
        { key: 'day', label: this.$t('Day'), sortable: false },
        { key: 'openTime', label: this.$t('OpenTime'), sortable: false },
        { key: 'closeTime', label: this.$t('CloseTime'), sortable: false },
        { key: 'openTime2', label: this.$t('OpenTime2'), sortable: false },
        { key: 'closeTime2', label: this.$t('CloseTime2'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    themeConfig() {
      return $themeConfig
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    getUserData() {
      return localStorage.getItem('userData') != null
        ? JSON.parse(localStorage.getItem('userData'))
        : null
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    if (this.$route.params.id !== 'add') {
      // eslint-disable-next-line no-unused-vars
      const obj = {
        id: this.$route.params.id,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const respData = result.data.data
          this.selectedData(respData)
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    } else {
      const day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      for (let index = 0; index < day.length; index += 1) {
        this.workingPeriod.push({
          day: day[index],
          openTime: {
            hour: null,
            minute: null,
          },
          closeTime: {
            hour: null,
            minute: null,
          },
          openTime2: {
            hour: null,
            minute: null,
          },
          closeTime2: {
            hour: null,
            minute: null,
          },
          status: false,
        })
      }
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/getServiceType`)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    store
      .dispatch(`${STORE_MODULE_NAME}/getTemplateTime`)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return {
      STORE_MODULE_NAME,
    }
  },
  methods: {
    selectServiceType3Id(data) {
      this.selected3 = data
    },
    selectServiceType2Id(data) {
      this.selected2 = data
      this.selected3 = null
    },
    selectServiceType1Id(data) {
      this.serviceTypeId = data
      this.selected2 = null
      this.selected3 = null
    },
    setCenter(value, type) {
      if (type === 'lat') {
        this.dataCenter.lat = parseFloat(value)
        this.lat = parseFloat(value)
        this.partnerLocation.coordinates[1] = parseFloat(value)
      } else {
        this.dataCenter.lng = parseFloat(value)
        this.lng = parseFloat(value)
        this.partnerLocation.coordinates[0] = parseFloat(value)
      }
    },
    formatData(data) {
      // จัดรูปแบบข้อมูล

      return data.map(e => {
        const indexLang = e.name.findIndex(index => index.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return {
            _id: e._id,
            name: e.name[indexLang].value,
          }
        }
        return {
          _id: e._id,
          name: e.name[0].value,
        }
      })
    },
    drawInMap(location) {
      // กดแล้วเปลี่ยน center
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      this.partnerLocation.coordinates[0] = lng
      this.partnerLocation.coordinates[1] = lat
      this.dataCenter.lat = lat
      this.dataCenter.lng = lng
      this.lat = lat
      this.lng = lng
      this.$refs.mapRef.$mapPromise.then(map => {
        map.panTo({ lat, lng })
      })
    },
    setLocal(location) {
      // ลากแล้วเปลี่ยน center
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      this.partnerLocation.coordinates[0] = lng
      this.partnerLocation.coordinates[1] = lat
      this.dataCenter.lat = lat
      this.dataCenter.lng = lng
      this.lat = lat
      this.lng = lng
    },
    removeCover(index) {
      this.cover.splice(index, 1)
    },
    inputCoverRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.cover.push(e.target.result)
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
          this.$refs.refInputCover.reset()
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetCover() {
      this.cover = []
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
          this.$refs.refInputImage.reset()
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    templateTime(data) {
      if (data) {
        this.workingPeriod = data
      } else {
        const day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        for (let index = 0; index < day.length; index += 1) {
          this.workingPeriod.push({
            day: day[index],
            openTime: {
              hour: null,
              minute: null,
            },
            closeTime: {
              hour: null,
              minute: null,
            },
            openTime2: {
              hour: null,
              minute: null,
            },
            closeTime2: {
              hour: null,
              minute: null,
            },
            status: false,
          })
        }
      }
    },
    templateDetail(data) {
      if (data == null) {
        this.questions = []
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < data.questions.length; index += 1) {
          const element = data.questions[index]
          const listOption = []
          for (let j = 0; j < element.listOption.length; j += 1) {
            const listOptionData = element.listOption[j]
            listOption.push({
              _id: listOptionData._id,
              option: listOptionData.option,
              select: false,
            })
          }
          this.questions.push({
            icon: element.icon,
            question: element.question,
            answer: '',
            type: element.type,
            listOption,
            required: element.required,
          })
        }
      }
    },
    timeFomat(n) {
      if (n !== null) {
        if (n.toString().length === 1) {
          return `0${n.toString()}`
        }
      }
      return n
    },
    selectedData(data) {
      if (data.servicePackageId) {
        this.servicePackageId = data.servicePackageId
      }
      if (data.serviceAreaId) {
        this.serviceAreaId = data.serviceAreaId
      }
      if (data.serviceTypeId) {
        this.serviceTypeId = data.serviceTypeId
        this.selected1 = data.serviceTypeId
      }
      if (data.serviceTypesId) {
        if (data.serviceTypesId.length > 0) {
          if (data.serviceTypesId.length === 3) {
            // eslint-disable-next-line prefer-destructuring
            this.selected1 = data.serviceTypesId[0]
            // eslint-disable-next-line prefer-destructuring
            this.selected2 = data.serviceTypesId[1]
            // eslint-disable-next-line prefer-destructuring
            this.selected3 = data.serviceTypesId[2]
          } else if (data.serviceTypesId.length === 2) {
            // eslint-disable-next-line prefer-destructuring
            this.selected1 = data.serviceTypesId[0]
            // eslint-disable-next-line prefer-destructuring
            this.selected2 = data.serviceTypesId[1]
          } else if (data.serviceTypesId.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.selected1 = data.serviceTypesId[0]
          }
        }
      }
      if (data.image) {
        this.image = data.image
      }
      if (data.cover) {
        this.cover = data.cover
      }
      if (data.firstName) {
        this.firstName = data.firstName
      }
      if (data.lastName) {
        this.lastName = data.lastName
      }
      if (data.workingPeriod) {
        if (data.workingPeriod.length === 7) {
          this.workingPeriod = data.workingPeriod
        } else {
          const day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
          for (let index = 0; index < day.length; index += 1) {
            this.workingPeriod.push({
              day: day[index],
              openTime: {
                hour: null,
                minute: null,
              },
              closeTime: {
                hour: null,
                minute: null,
              },
              openTime2: {
                hour: null,
                minute: null,
              },
              closeTime2: {
                hour: null,
                minute: null,
              },
              status: false,
            })
          }
        }
      }
      if (data.callTableStatus) {
        this.callTableStatus = data.callTableStatus
      }
      if (data.callTable) {
        if (data.callTable.length > 0) {
          this.callTable = data.callTable
        } else {
          this.callTable = [
            {
              start: '', // ระยะทางเริ่มต้น
              end: '', // ระยะสิ้นสุด
              price: '', // ราคา
            },
          ]
        }
      }
      if (data.partnerLocation.coordinates.length === 2) {
        const lat = parseFloat(data.partnerLocation.coordinates[1])
        const lng = parseFloat(data.partnerLocation.coordinates[0])
        this.partnerLocation.coordinates[0] = lng
        this.partnerLocation.coordinates[1] = lat
        this.dataCenter.lat = lat
        this.dataCenter.lng = lng
        this.lat = lat
        this.lng = lng
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({ lat, lng })
        })
      }
      if (data.gender) {
        this.gender = data.gender
      }
      if (data.licensePlate) {
        this.licensePlate = data.licensePlate
      }
    },
    deleteCallTable(index) {
      this.callTable.splice(index, 1)
    },
    addCallTable() {
      this.callTable.push({
        start: '', // ระยะทางเริ่มต้น
        end: '', // ระยะสิ้นสุด
        price: '', // ราคา
      })
    },
    validationForm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$refs.formData.validate().then(success => {
              if (success) {
                const serviceTypesId = [this.selected1, this.selected2, this.selected3].filter(e => e !== null)
                const obj = {
                  id: this.$route.params.id === 'add' ? null : this.$route.params.id,
                  serviceTypeId: this.serviceTypeId,
                  serviceTypesId,
                  servicePackageId: this.servicePackageId,
                  serviceAreaId: this.serviceAreaId,
                  image: this.image,
                  cover: this.cover,
                  firstName: this.firstName,
                  lastName: this.lastName,
                  tel: this.tel,
                  workingPeriod: this.workingPeriod,
                  callTableStatus: this.callTableStatus,
                  callTable: this.callTable,
                  partnerLocation: this.partnerLocation,
                  gender: this.gender,
                  licensePlate: this.licensePlate,
                }
                if (obj.id !== null) {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false
                      //   this.$router.replace({ name: 'select-profile' })

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                } else {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false
                      this.$router.replace({ name: 'select-profile' })

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t('Error Validator'),
                  },
                })
              }
            })
          }
        })
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to cancel')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$router.replace({ name: 'select-profile' })
          }
        })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      useJwt.logout()

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.replace({ name: 'auth-login' })
      store.commit('appConfig/UPDATE_MENU_SERVICE', '')
      store.commit('appConfig/RESET_NAVBAR_TITLE')
      store.commit('appConfig/RESET_NAVBAR_IMAGE')
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.btn-remove {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}
.imgs-show {
  // width: 100%;
  height: 100px;
}
</style>
