// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respServiceTypeData: null,
    respTemplateTimeData: null,
    respTemplateDetailData: null,
  },
  getters: {
  },
  mutations: {
    GET_SERVICE_TYPE(state, payload) {
      state.respServiceTypeData = payload
    },
    GET_TEMPLATE_TIME(state, payload) {
      state.respTemplateTimeData = payload
    },
    GET_TEMPLATE_DETAIL(state, payload) {
      state.respTemplateDetailData = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/partner/hero/profilePartner/view?id=${item.id}`)
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/partner/hero/profilePartner/add', item)
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/partner/hero/profilePartner/update', item)
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getServiceType({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/partner/hero/profilePartner/getServiceType')
          .then(response => {
            commit('GET_SERVICE_TYPE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getTemplateTime({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/partner/hero/profilePartner/getTemplateTime')
          .then(response => {
            commit('GET_TEMPLATE_TIME', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
